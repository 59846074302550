// *************************************
//
// Entry
//
// *************************************

.entry {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  min-width: 360px;
  max-width: 500px;
  margin: var(--s-3) var(--s-1) 0;
}

.entry-read {
  position: relative;
  background-color: var(--color-bg-depth-2);
  overflow: hidden;

  &.is-highlighted {
    background-color: var(--color-bg-depth-3);
  }

  .entries[dir='ltr'] &.has-preview {
    margin-right: 0;
  }

  .entries[dir='rtl'] &.has-preview {
    margin-left: 0;
  }

  .entry-natures {
    margin-top: var(--s-3);
  }
}

.entry-transliteration {
  margin: 0;
  padding: 0 7px; // Otherwise italic display of these font implies an overflow on the left for "j"
  font-size: 36px;

  @media only print {
    font-size: 56px;
  }
}

.entry-periods {
  margin: var(--s0) 0 var(--s2);
}

.entry-read,
.entry-preview {
  .entry-main-graphies {
    &:empty {
      display: none;
    }

    &:not(&:first-child) {
      border-top: 1px solid var(--input-color);
    }
    border-bottom: 1px solid var(--input-color);

    > div + div {
      border-top: 1px solid var(--input-color);
    }
  }
}

.entry-natures + .entry-main-graphies {
  margin-top: var(--s-1);
}

// -------------------------------------
// TOOLBARS
// -------------------------------------

.entry-header {
  display: flex;
  justify-content: flex-end;
  padding: 6px var(--s-1) 8px;

  .entry-read & {
    padding: 0 var(--s-1);
    justify-content: space-between;
    transition: opacity 0.15s ease-in-out;
  }

  @media (min-width: 520px) {
    .entry-read:not(.is-highlighted)
      &
      > div:first-child
      > *:not(:first-child):not(:nth-child(2)):not(:nth-child(3)),
    .entry-read:not(.is-highlighted) & > div:last-child > * {
      opacity: 0;
    }

    .entry-read:hover
      &
      > div:first-child
      > *:not(:first-child):not(:nth-child(2)):not(:nth-child(3)),
    .entry-read:hover & > div:last-child > * {
      opacity: 1;
    }
  }
}

.entry-header-right {
  display: flex;
  align-items: center;
}

// -------------------------------------
// Close button
// -------------------------------------

.entry-close {
  order: 1;
}

// -------------------------------------
// Permalink button
// -------------------------------------

.entry-permalink {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--s-2);
}

// -------------------------------------
// Actions
// -------------------------------------

.entry-actions {
  order: 4;
  flex-grow: 10;
}

// -------------------------------------
// Data zone
// -------------------------------------

// -------------------------------------
// Nuances and languages
// -------------------------------------

.entry-nuance-container {
  display: flex;
  gap: 6px;
  padding: var(--s-1) 0;
}

.entry-nuance {
  flex: 1 1 auto;
  font-family: var(--ff-serif);
  font-size: 21px;
}

.entry-nuance-lang-menu {
  position: relative;

  .menu {
    left: -11px;
  }
}

.nuance-flag-selector {
  display: flex;
  align-items: center;
  background-color: transparent;
  gap: 4px;
  border: 0;
  padding: 0;
  margin: 13px 0 5px;
  cursor: pointer;
  opacity: 80%;

  &:hover,
  &.is-menu-open {
    opacity: 100%;
  }

  > svg {
    flex: 0 0 auto;
    width: 8px;
    fill: var(--color-bg-depth-6);
  }
}

.entry-locked-modal {
  position: absolute;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 276px;
  max-width: 90%;
  border-radius: 10px;
  background-color: var(--color-bg-depth-1);
  color: var(--color-text);
  padding: 50px 30px 25px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.entry-locked-modal-title {
  font-size: 18px;
  font-weight: 700;
  fill: currentcolor;
}

.entry-locked-modal-unlock-button {
  border: 0;
  background-color: transparent;
  color: var(--color-text-inactive);
  padding: 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--color-text-subtle);
  }
}

.entry-authors {
  .entry-edition & {
    padding: 0 var(--s-1);
  }

  .entry-read &,
  .entry-preview & {
    margin-top: var(--s1);

    .input-container[data-layout='horizontal'] {
      align-items: flex-start;

      label {
        top: 6px;
      }
    }

    .entry-authors-value {
      font-family: var(--ff-serif);
      font-size: 16px;
    }
  }
}
