.graphy-attestation-title-holder {
  background-color: var(--color-bg-depth-4);
  padding: var(--s-1);

  .entry-nuance-container {
    padding: 0;
  }

  .entry-nuance {
    font-size: 15px;
  }
}

.graphy-attestation-title-holder-transliteration {
  margin-bottom: 0;
  font-size: 18px;
  text-indent: 0.2em;
}

.graphy-attestation-title-holder-unpublished-label {
  color: var(--grey-5);
  font-style: normal;
  font-size: 16px;
}
