.individual-titles-title {
  position: relative;
  background-color: var(--color-bg-depth-2);
  padding: var(--s-2) var(--s-1);
  font-family: var(--ff-serif);
  font-style: italic;

  & + & {
    margin-top: var(--s-3);
  }
}

.individual-titles-title-unpublished-label {
  color: var(--grey-5);
  font-style: normal;
}

.individual-titles-title-action {
  display: none;
  position: absolute;
  top: var(--s-2);
  right: var(--s-2);

  .individual-titles-title:hover & {
    display: block;
  }
}

.individual-titles-section-label {
  color: var(--color-text-inactive);
  text-transform: uppercase;
  font-family: var(--ff-sans);
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: var(--s-3) 0 0;
  margin-bottom: var(--s-2);

  .entry-read &,
  .entry-preview & {
    border-top: 2px solid var(--input-color);
  }
}

.individual-titles-read-title {
  width: 100%;
  padding: var(--s-3) var(--s-1);
  border: 0;
  background-color: transparent;
  color: var(--color-text);
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: var(--color-bg-depth-3);
  }

  & + & {
    border-top: 1px solid var(--input-color);
  }

  .individual-titles-read-title-transliteration {
    font-family: var(--ff-serif);
    font-size: 21px;
    font-style: italic;
  }

  .entry-nuance-container {
    padding: var(--s-2) 0;
  }

  .entry-nuance {
    font-size: 16px;
  }
}
