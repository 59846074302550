.nature-selector {
  .menu {
    max-height: 200px;
    overflow-y: auto;
  }

  > div {
    min-height: 25px;
  }
}
