// *************************************
//
// Entries workspace
//
// *************************************

.workspace {
  position: relative;
  display: flex;
  height: calc(100% - 54px);

  @media (min-width: 520px) {
    height: calc(100% - 80px);
  }
}

.entries {
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  display: flex;
  overflow-x: auto;

  .entries-last-spacer {
    padding-inline-end: 320px;

    @media (min-width: 500px) {
      padding-inline-end: 500px;
    }
  }

  .overlay {
    right: -5000px;
  }
}
