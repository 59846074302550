// -------------------------------------
// TOOLBAR
// -------------------------------------

.entry-edition .entry-header {
  position: relative;
  z-index: var(--z-index-entries);
  background-color: var(--color-edition);
  justify-content: space-between;
}

// -------------------------------------
// Edit sandwich button
// -------------------------------------

.entry-edition-menu {
  .entry-permalink-tooltip {
    top: 31px;
    .tooltip-text {
      background-color: var(--color-bg-depth-6);
      padding: 6px 10px;
    }
  }
}

.entry-edition-popup-menu {
  background-color: var(--color-bg-depth-1);
  border: 1px solid var(--color-bg-depth-1);
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  padding: 4px;

  .menu-item {
    order: 1;
    width: 100%;
    border: 0;
    border-radius: 3px;
    background-color: var(--color-bg-depth-1);
    color: var(--color-text);
    font-size: 13px;
    font-weight: 400;
    font-family: var(--ff-sans);
    padding: 3px 10px;
    display: flex;

    &:hover {
      background-color: var(--color-bg-depth-2);
    }
  }

  .entry-edition-menu-permalink {
    width: 13px;
    height: 13px;
  }
}

.entry-publish-button-spinner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
