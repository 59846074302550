.source-info {
  position: relative;
  padding: var(--s-2) 0 0;
  color: inherit;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.15;
    border-top: 1px solid currentcolor;
  }

  &[data-layout='horizontal'] {
    display: flex;
    align-items: center;
  }
}

.source-info-label {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.04em;
  color: currentcolor;
  opacity: 0.5;
  text-transform: uppercase;
  margin-bottom: var(--s-3);
  white-space: nowrap;

  &[data-layout='horizontal'] & {
    margin: 0 var(--s-1) 0 0;
  }
}

.source-info-content {
  font-size: 16px;
  font-family: var(--ff-serif);

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }

    &:focus {
      color: inherit;
    }
  }
}
