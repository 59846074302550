// *************************************
//
// Modal
//
// *************************************

.modal-overlay {
  position: absolute;
  z-index: calc(var(--z-index-modal) - 1);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;

  @media only print {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin-left: 0 !important;
  }
}

.modal-content {
  position: absolute;
  z-index: var(--z-index-modal);
  left: 50%;
  display: inline-block;
  background-color: var(--color-bg-depth-1);
  transform: translate(-50%, 50%);
  border-radius: 6px;

  &:focus {
    outline: 0;
  }

  @media only print {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    border-radius: 0;
  }
}

.modal-close-button {
  position: absolute;
  top: 6px;
  right: 6px;
  fill: var(--color-text-subtle);

  &:hover {
    fill: var(--color-text);
  }

  @media only print {
    display: none;
  }
}

.modal-body {
  display: flex;
  width: 420px;
  flex-direction: column;
  text-align: center;
}

.modal-header,
.modal-description,
.modal-actions {
  padding-right: 36px;
  padding-left: 36px;
}

.modal-header {
  padding-top: 36px;
  margin-bottom: 24px;

  &:last-child {
    padding-bottom: 36px;
    margin-bottom: 0;
  }

  @media only print {
    &:not(:has(.modal-title)) {
      display: none;
    }
  }
}

.modal-title {
  margin: 0 0 12px;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}

.modal-description {
  color: var(--color-text-subtle);
  font-size: 15px;
  font-weight: 400;

  .modal-title + & {
    margin-top: 24px;
  }
}

.modal-description {
  flex: 0 1 auto;
  margin-bottom: 24px;

  &:last-child {
    padding-bottom: 36px;
    margin-bottom: 0;
  }
}

.modal-actions {
  padding-bottom: 36px;
}
