.logged-user-container {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 15px;
  white-space: nowrap;

  @media (min-width: 700px) {
    display: flex;
  }

  a {
    font-family: var(--ff-serif);
    font-size: 15px;
    color: var(--color-text-inactive);

    &:hover {
      color: var(--color-text-inverse);
    }
  }

  button {
    font-size: 14px;
    font-family: var(--ff-sans);
    font-weight: 700;
    text-transform: uppercase;
  }
}
