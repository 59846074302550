.link-editor {
  display: flex;
  min-width: 210px;
  max-width: 210px;
  align-items: flex-start;
  padding: 4px 10px;
  background-color: var(--color-bg-depth-1);
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);

  input {
    position: relative;
    border: 0;
    border-bottom: 1px solid var(--input-color);
    flex: 1 1 auto;
    margin: 0;
    padding: 6px 0;
    font-family: var(--ff-serif);
    font-size: 14px;
    font-style: italic;

    &:focus {
      outline: 0;
      border-bottom-color: var(--input-color-hover);
    }
  }
}

.link-editor-autocomplete-item {
  font-family: var(--ff-serif);
  padding: var(--s-2) var(--s-1);
  border-bottom: 1px solid var(--input-color);
  cursor: pointer;

  &[data-published='false'] {
    background-color: rgb(23, 155, 255, 0.1);
  }

  > div:first-child {
    font-size: 16px;
    font-style: italic;
  }

  > div:last-child {
    font-size: 13px;
  }
}

.autocomplete-container {
  z-index: var(--z-index-dropdown);
  padding: var(--s-4);
  border-radius: 6px;
  background-color: var(--color-bg-depth-1);
  max-height: 280px;
  overflow-y: auto;
  box-shadow: 0px 2px 8px var(--menu-shadow-color);
}
