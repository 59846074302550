.individual-name {
  .entry-nuance {
    font-size: 17px;
    padding: 0 var(--s-3);
  }

  & + & {
    border-top: 1px solid var(--border-color);
    padding-top: var(--s0);
  }
}

.individual-name-graphy {
  border-top: 1px solid var(--input-color);
  border-bottom: 1px solid var(--input-color);
}

.individual-name-transliteration {
  font-size: 21px;
  padding: 0 var(--s-3);
}

.individual-name--edition {
  position: relative;
  background-color: var(--color-bg-depth-2);
  padding: var(--s-1);

  .graphy-image .graphy-image-bg[data-publishable='true'] {
    background-color: transparent;
  }
}

.individual-name-actions {
  position: absolute;
  top: var(--s-1);
  right: var(--s-1);
}

.individual-name-graphy-dropzone {
  label {
    background-color: var(--color-bg-depth-3);
  }

  label:hover {
    background-color: var(--color-bg-depth-4);
  }
}

.individual-name-graphy-dropzone-img {
  position: relative;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;

  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #1fabff;
    mix-blend-mode: lighten;
  }
}
