// *************************************
//
// Style
//
// *************************************

// -------------------------------------
// Fonts
// -------------------------------------

@font-face {
  font-family: 'PT Sans';
  font-display: fallback;
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans Regular'), local('PTSans-Regular'),
    url('../fonts/PTSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'PT Sans';
  font-display: fallback;
  font-style: italic;
  font-weight: 400;
  src: local('PT Sans Italic'), local('PTSans-Italic'),
    url('../fonts/PTSans-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'PT Sans';
  font-display: fallback;
  font-style: normal;
  font-weight: 700;
  src: local('PT Sans Bold'), local('PTSans-Bold'),
    url('../fonts/PTSans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'PT Sans';
  font-display: fallback;
  font-style: italic;
  font-weight: 700;
  src: local('PT Sans Bold Italic'), local('PTSans-BoldItalic'),
    url('../fonts/PTSans-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'GentiumPlusW';
  font-display: block;
  src: local('Gentium Plus'), local('GentiumPlus'),
    url('../fonts/GentiumPlus-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'GentiumPlusW';
  font-display: block;
  font-style: italic;
  src: local('Gentium Plus Italic'), local('GentiumPlus-Italic'),
    url('../fonts/GentiumPlus-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'GentiumPlusW';
  font-display: block;
  font-weight: 700;
  src: local('Gentium Plus Bold'), local('GentiumPlus-Bold'),
    url('../fonts/GentiumPlus-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'GentiumPlusW';
  font-display: block;
  font-style: italic;
  font-weight: 700;
  src: local('Gentium Plus Bold Italic'), local('GentiumPlus-BoldItalic'),
    url('../fonts/GentiumPlus-BoldItalic.woff2') format('woff2');
}

// -------------------------------------
// Variables
// -------------------------------------
:root {
  --ff-serif: 'GentiumPlusW', Georgia, serif;
  --ff-sans: 'PT Sans', 'Helvetica Neue', Arial, sans-serif;

  // ----- Base colors ----- //
  --white: white;
  --grey-1: #f9f9f9;
  --grey-2: #f0f0f0;
  --grey-3: #e7e7e7;
  --grey-4: #dddcdb;
  --grey-5: #b3b1af;
  --grey-6: #8a8a8a;
  --grey-7: #4e4e44;
  --grey-8: #42423f;
  --black: black;
  --blue-1: #f3faff;
  --blue-2: #dcf0ff;
  --blue-3: #d0ebff;
  --blue-4: #b2deff;
  --blue-5: #a2d7ff;
  --blue-6: #70c2ff;
  --blue-7: #46afff;
  --blue-8: #179bff;
  --blue: #179bff;
  --green-0: #dde0dc;
  --green-1: #d3d9d3;
  --green-2: #5b825a;
  --green-3: #527551;
  --green-4: #566d51;
  --red-1: #d05353;
  --red-2: #b84949;
  --orange-1: #e39620;
  --orange-2: #f27d14;

  // ----- Palette ----- //
  --color-bg-depth-1: var(--white);
  --color-bg-depth-2: var(--grey-2);
  --color-bg-depth-3: var(--grey-3);
  --color-bg-depth-4: var(--grey-4);
  --color-bg-depth-5: var(--grey-7);
  --color-bg-depth-6: var(--grey-8);

  --color-text: var(--grey-8);
  --color-text-subtle: var(--grey-7);
  --color-text-medium: var(--grey-6);
  --color-text-inactive: var(--grey-5);
  --color-text-inverse: var(--grey-1);

  --color-edition: var(--blue);
  --color-alert: var(--red-1);

  --input-color: var(--grey-4);
  --input-color-hover: var(--grey-5);
  --input-color-focus: var(--grey-6);
  --border-color: var(--grey-6);
  --menu-shadow-color: rgba(78, 78, 68, 0.4);

  [data-publishable='false'] {
    --color-bg-depth-1: var(--white);
    --color-bg-depth-2: var(--grey-2);
    --color-bg-depth-3: var(--grey-3);
    --color-bg-depth-4: var(--grey-4);
    --color-bg-depth-5: var(--grey-7);
    --color-bg-depth-6: var(--grey-8);

    --color-text: var(--grey-8);
    --color-text-subtle: var(--grey-7);
    --color-text-medium: var(--grey-6);
    --color-text-inactive: var(--grey-5);
    --color-text-inverse: var(--grey-1);

    --input-color: var(--grey-4);
    --input-color-hover: var(--grey-5);
    --input-color-focus: var(--grey-6);
    --border-color: var(--grey-6);
  }

  [data-publishable='true'] {
    --color-bg-depth-2: var(--blue-2);
    --color-bg-depth-3: var(--blue-3);
    --color-bg-depth-4: var(--blue-4);
    --color-bg-depth-5: var(--blue-7);
    --color-bg-depth-6: var(--blue-8);

    --color-text: var(--blue-8);
    --color-text-subtle: var(--blue-7);
    --color-text-medium: var(--blue-6);
    --color-text-inactive: var(--blue-5);
    --color-text-inverse: var(--blue-1);

    --input-color: var(--blue-4);
    --input-color-hover: var(--blue-5);
    --input-color-focus: var(--blue-6);
    --border-color: var(--blue-6);
  }

  // ----- Base var ----- //
  --base-font-size: 16px;
  --base-line-height: 1.5;
  --page-header-height: 100px;

  // ----- Space var ----- //
  --ratio: 1.5;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));

  // ----- zIndex ----- //
  --z-index-entries: 100;
  --z-index-footer: 150;
  --z-index-header: 200;
  --z-index-dropdown: 225;
  --z-index-modal: 250;
  --z-index-tooltip: 1000;
}

// -------------------------------------
// Modules : import order is important for css rules applications
// -------------------------------------
@import 'core/utilities';

// -------------------------------------
// Base styles
// -------------------------------------

html,
body {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: var(--ff-sans);
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
  color: var(--color-text);
  overflow: hidden;

  @media only screen {
    background-color: var(--color-bg-depth-2);
  }
}

* {
  box-sizing: border-box;
}

// -------------------------------------
// Block Content
// -------------------------------------

h2 {
  margin-top: 4px;
  margin-bottom: 8px;
  font-weight: normal;
}

h3 {
  margin-top: 6px;
  margin-bottom: 12px;
  font-weight: normal;
}

h4 {
  margin-top: 2px;
  margin-bottom: 8px;
  margin-left: 6px;
  font-weight: normal;
}

h5 {
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: normal;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// -------------------------------------
// Form Content
// -------------------------------------

::-webkit-input-placeholder {
  color: var(--color-input);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: var(--color-input);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color-input);
}

:-ms-input-placeholder {
  color: var(--color-input);
}

fieldset,
.fieldset {
  border-bottom: 1px solid var(--color-text);
  padding: 0.35em 0.625em 0.75em;
}

legend,
.legend {
  font-size: 32px;
  line-height: 48px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
}

button:focus,
input[type='submit']:focus {
  outline: none;
}

input[type='text'],
input[type='search'],
input[type='password'],
textarea,
select {
  outline: 0;
  box-sizing: border-box;
}

textarea {
  overflow: hidden;
  max-width: 100%;
}

input[type='search'] {
  background-position: 98% center;
  background-repeat: no-repeat;
  background-color: white;
  //padding-right: 32px;
}

input[type='submit'] {
  margin-top: 20px;
}

input[type='radio'],
input[type='checkbox'] {
  margin: 10px 0;

  + label {
    vertical-align: bottom;
    color: var(--color-text);
    font-size: 20px;
    line-height: 1.42857;
    margin-right: 20px;
  }
}

label,
.label {
  margin-top: 10px;
  display: inline-block;
  font-size: 12px;
}

.small-label {
  font-size: 13px;
}

.radio-title {
  display: block;
}

.controls-group {
  margin: 12px 0;
}

// -------------------------------------
// Inline Content
// -------------------------------------

a {
  color: var(--color-text-subtle);
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-text);
  }
}

p {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.action-link {
  text-decoration: underline;
  cursor: pointer;
}

.link {
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  font-family: var(--ff-sans);
  color: var(--color-text-subtle);

  &:hover,
  &:active,
  &:focus {
    color: var(--color-text);
  }
}

.link--clear {
  padding: 0;
  border: 0;
  background-color: transparent;
  text-decoration: none;
  color: var(--color-bg-depth-1);

  &:hover,
  &:active,
  &:focus {
    color: var(--color-bg-depth-2);
  }
}
