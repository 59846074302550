.accordion {
  &[data-has-content='false'] {
    pointer-events: none;

    summary {
      cursor: default;
    }

    summary::before {
      content: '';
    }
  }

  summary {
    position: relative;
    z-index: 10;
    display: block;
    cursor: pointer;

    @media only screen {
      padding-inline-start: 28px;
    }
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary::marker {
    display: none;
  }

  @media only screen {
    summary::before {
      content: '▶';
      font-family: 'Apple symbols', sans-serif;
      font-size: 10px;
      color: var(--color-text-inactive);
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translate(0, -50%) rotate(0);
      transform-origin: center;
      transition: 0.2s transform ease;

      [dir='rtl'] & {
        left: auto;
        right: 8px;
        transform: translate(0, -50%) rotate(180deg);
      }
    }
  }

  details[open] > summary:before {
    transform: translate(0, -50%) rotate(90deg);
    transition: 0.45s transform ease;
  }

  details {
    overflow: hidden;
    padding: 0;

    @media only print {
      overflow: visible !important;
    }
  }

  details > *:not(summary) {
    position: relative;
    z-index: 1;
    overflow: hidden;

    @media only print {
      overflow: visible !important;
    }
  }

  @media only print {
    details,
    details > * {
      display: block !important;
    }
  }
}
