.title-holder {
  padding: var(--s-2) var(--s-1);
  color: var(--color-text);
  cursor: pointer;

  &:hover {
    background-color: var(--color-bg-depth-3);
  }

  & + & {
    border-top: 1px solid var(--input-color);
  }

  .entry-nuance-container {
    padding: 0;
  }

  .entry-nuance {
    font-size: 16px;
  }
}

.title-holder-transliteration {
  margin-bottom: 0;
  font-size: 21px;
  text-indent: 0.2em;
}
