.main-nuances-tabs-selectors {
  display: flex;
  justify-content: flex-end;
  gap: var(--s-2);
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.main-nuances-tabs-selector {
  padding: var(--s-2);
  border-radius: 0 0 3px 3px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-bg-depth-2);
  }

  &.is-selected {
    background-color: var(--color-bg-depth-3);
  }
}

.main-nuances-editor {
  display: block;
  width: 100%;
  font-size: 22px;
  font-family: var(--ff-serif);
  background-color: transparent;
  color: var(--color-text);

  .editor .public-DraftEditor-content[contenteditable='true'],
  .editor .public-DraftEditorPlaceholder-inner {
    padding-inline-start: var(--s-2);
  }

  &::placeholder {
    font-family: var(--ff-serif);
    color: var(--color-text-inactive);
    opacity: 1;
  }
}
