.input-container {
  flex: 1 1 auto;

  &:not(.input-container--borderless) {
    border-bottom: 1px solid var(--input-color);
  }

  &:hover {
    border-bottom-color: var(--input-color-hover);
  }

  &:focus-within {
    border-bottom-color: var(--input-color-focus);
  }

  &.is-read-only {
    pointer-events: none;
    border-bottom-color: transparent;
  }

  &[data-layout='horizontal'] {
    display: flex;
    align-items: center;

    label {
      position: relative;
      top: 2px;
      margin: 0;
      margin-inline-end: var(--s-1);
    }
  }

  label {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: var(--color-text-medium);
    text-transform: uppercase;
    margin-bottom: var(--s-3);
    white-space: nowrap;
  }

  textarea,
  input {
    display: block;
    width: 100%;
    padding: 0.4em 0;
    border: 0;
    background-color: transparent;
    color: var(--color-text);
    line-height: 1.4;

    &::placeholder {
      color: var(--color-text-inactive);
      opacity: 1;
    }

    &:focus {
      outline: 0;
    }
  }

  &.is-extra-small input,
  &.is-extra-small textarea {
    font-size: 16px;
  }

  &.is-small input,
  &.is-small textarea {
    font-size: 18px;
  }

  &.is-medium input,
  &.is-medium textarea {
    font-size: 22px;
  }

  &.is-large input,
  &.is-large textarea {
    font-size: 36px;
  }

  &.is-serif .editor,
  &.is-serif input,
  &.is-serif textarea {
    font-family: var(--ff-serif);

    &::placeholder {
      font-family: var(--ff-serif);
    }
  }

  &.is-italic .editor,
  &.is-italic input,
  &.is-italic textarea {
    font-style: italic;

    &::placeholder {
      font-style: italic;
    }
  }

  &.is-italic.is-serif input {
    text-indent: 0.25em;
  }
}
