.entry-print-modal {
  top: 10px;
  transform: translate(-50%, 0);

  @media only print {
    top: 0;
    transform: translate(0, 0);
  }

  .modal-body {
    width: 540px;
    max-width: 98vw;
    text-align: left;
    max-height: calc(100vh - 20px);
    overflow-y: auto;

    @media only print {
      width: 100vw;
      max-width: none;
      max-height: none;
      height: auto;
      overflow-y: visible;
    }
  }
}

.entry-print-modal-print-button {
  position: absolute;
  z-index: var(--z-index-modal);
  top: 6px;
  left: 6px;

  @media only print {
    display: none;
  }
}

@media only print {
  body.is-opened #root {
    display: none;
  }
}
