.comments-editor {
  font-size: 16px;
  line-height: 1.5;
  font-family: var(--ff-serif);
  color: var(--color-text);

  .public-DraftEditor-content[contenteditable] {
    border: 0;
  }
}
