.link-decorator {
  font-style: italic;
  color: var(--color-text-subtle);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);

  [contenteditable='false'] & {
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.link-decorator-tooltip {
  display: flex;
  align-items: center;
  min-height: 37px;
  font-family: var(--ff-serif);
  font-size: 16px;

  [data-entry-id] {
    cursor: pointer;
  }

  > div span:first-child {
    font-size: 20px;
  }
}
