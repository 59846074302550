.search-lang-buttons {
  display: flex;
  align-items: center;
}

.search-lang-button {
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  padding: 0 13px;
  border: 1px solid var(--input-color);
  border-left-width: 0;
  background-color: transparent;
  color: var(--color-text);
  height: 28px;

  [dir='ltr'] &:first-child {
    border-left-width: 1px;
    border-radius: 6px 0 0 6px;
  }

  [dir='ltr'] &:last-child {
    border-radius: 0 6px 6px 0;
  }

  [dir='rtl'] &:first-child {
    border-radius: 0 6px 6px 0;
  }

  [dir='rtl'] &:last-child {
    border-left-width: 1px;
    border-radius: 6px 0 0 6px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &.is-selected {
    border-color: var(--color-text);
    background-color: var(--color-text);
    box-shadow: 1px 1px 4px rgba(66, 66, 63, 0.3);
  }

  .flag-icon {
    outline: 1px solid var(--color-bg-depth-3);
    opacity: 80%;
  }

  &.is-selected .flag-icon {
    opacity: 90%;
  }

  &:hover .flag-icon {
    opacity: 70%;
  }

  &.is-selected:hover .flag-icon {
    opacity: 100%;
  }

  @media (max-width: 819px) {
    &:not(:first-child):not(:last-child) {
      display: none;
    }
  }
}

.search-lang-button.is-trans,
.search-lang-button.is-comment {
  color: var(--color-text-subtle);

  &:hover {
    color: var(--color-text);
  }

  &.is-selected {
    color: var(--color-text-inverse);
  }
}

.search-lang-button.is-trans {
  font-size: 19px;
  font-weight: 400;
  font-family: var(--ff-serif);
  font-style: italic;
  line-height: 1;
  padding-bottom: 8px;
  width: 45px;
}

.search-lang-button.is-comment {
  padding-top: 4px;

  svg {
    width: 16px;
    height: 16px;
    fill: currentcolor;
  }
}
