.stack {
  @media only screen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  & > * + * {
    margin-top: var(--space, var(--s1));
  }
}
