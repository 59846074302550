.thesaurus-overlay {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
  overflow: auto;
}

.thesaurus-modal {
  width: calc(100% - var(--s0));
  margin: 36px auto;
  background-color: var(--white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
