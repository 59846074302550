.v4-tag {
  font-size: 11px;
  font-weight: 700;
  background-color: var(--border-color);
  color: var(--color-bg-depth-3);
  padding: 0 var(--s-3);
  border-radius: 3px;

  svg {
    display: block;
    stroke: currentcolor;
  }
}
