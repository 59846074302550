.mini-map-container {
  position: absolute;
  z-index: var(--z-index-footer);
  min-height: 36px;
  right: 0;
  bottom: 0;
  left: 0;
}

.mini-map {
  position: relative;
  background-color: var(--color-bg-depth-5);
  padding: 6px 5px;
  opacity: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0;
  transition: opacity 0.25s ease-in-out;
  box-shadow: 0 4px 14 rgba(0, 0, 0, 0.15);
  overflow: auto;

  .mini-map-container:hover & {
    opacity: 1;
  }
}

.mini-map-entry {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 14px 2px 2px;
  border-radius: 3px;
  font-family: var(--ff-serif);
  font-style: italic;
  font-size: 15px;
  white-space: nowrap;
  color: var(--color-text-inverse);
  cursor: pointer;
  touch-action: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }

  &[data-theme='blue'] {
    color: var(--blue-6);
  }

  &.is-dragging {
    opacity: 0.4;
    background-color: rgba(255, 255, 255, 0.25);
  }

  &.is-drag-overlay {
    color: var(--color-text-subtle);
    background-color: rgba(255, 255, 255, 1);
  }

  &[data-theme='blue'].is-drag-overlay {
    color: var(--blue);
  }

  &:not(:first-child)::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 1px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.25);
  }

  &:hover::before,
  &:hover + &::before {
    opacity: 0;
  }
}

.mini-map-entry-handle {
  cursor: grab;
  width: 12px;
  height: 18px;
  padding: 2px;
  opacity: 0;

  .mini-map-entry:hover & {
    opacity: 0.5;
  }

  &:hover {
    opacity: 1;
  }

  svg {
    display: block;
    fill: var(--color-text-inverse);
  }
}

.min-map-entry-content {
  flex: 1 1 auto;
}
