.checkbox-container {
  display: flex;
  align-items: center;
  gap: var(--s-2);

  &[data-label-placement='right'] {
    flex-direction: row-reverse;
  }

  label {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.4;
    display: inline-block;
    vertical-align: top;
    color: var(--color-text-subtle);
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;
  }

  &[data-size='small'] label {
    font-size: 11px;
    color: var(--color-text-medium);
  }

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'] {
      --border: var(--input-color);
      --background: var(--color-bg-depth-1);

      -webkit-appearance: none;
      -moz-appearance: none;
      height: 20px;
      width: 20px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--border);
      border-radius: 2px;
      background: var(--background);
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 6px;
        top: 2px;
        width: 5px;
        height: 9px;
        border: 2px solid var(--white);
        border-top: 0;
        border-left: 0;
        transform: rotate(43deg);
        opacity: 0;
        transition: transform var(--transition-dur-1, 0.3s) var(--transition-ease, ease),
          opacity var(--transition-dur-2, 0.2s);
      }

      &:checked {
        --background: var(--blue-7);
        --border: var(--blue-8);
        --transition-dur-1: 0.6s;
        --transition-dur-2: 0.3s;
        --transition-ease: cubic-bezier(0.2, 0.85, 0.32, 1.2);

        outline: 1px solid var(--border);

        &::after {
          opacity: 1;
        }
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;

        & + label {
          cursor: not-allowed;
        }
      }

      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --border: var(--blue-7);
          }
        }
      }

      &:focus {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
      }
    }

    &[data-size='small'] input[type='checkbox'] {
      height: 16px;
      width: 16px;

      &::after {
        left: 4px;
        top: 1px;
        width: 4px;
        height: 7px;
      }
    }
  }
}
