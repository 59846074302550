.graphy {
  display: flex;

  @media only print {
    display: block;
  }
}

.graphy-image {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px 0;

  @media only print {
    display: block;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .graphy-image-bg {
    position: relative;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.8;

    &[data-publishable='true'] {
      opacity: 1;
      background-color: white;

      &::after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #1fabff;
        mix-blend-mode: lighten;
      }
    }

    .graphy-container[data-publishable='true'] &[data-publishable='true'] {
      background-color: var(--blue-1);
    }

    &[dir='ltr'] {
      background-position: left top;
    }

    &[dir='rtl'] {
      background-position: right top;
    }
  }
}

.graphy-flags {
  pointer-events: all;
  padding-bottom: var(--s-2);
  flex: 0 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:empty {
    display: none;
  }

  > div:not(.counter-flag):first-child:last-child {
    margin-top: auto;
  }

  @media only print {
    display: none;
  }
}
