.source {
  padding: var(--s-1);

  & + & {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
  }
}

.source-back-nav-button {
  .thesaurus[dir='rtl'] & {
    transform: rotate(180deg);
  }
}

.source-title {
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  font-family: var(--ff-serif);
}

.source-id,
.source-periods {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.source-periods {
  font-family: var(--ff-serif);
  font-weight: 400;
}

.source-details {
  margin-top: var(--s0);

  .thesaurus[dir='rtl'] & {
    text-align: right;
  }

  summary {
    padding: 0 var(--s-2);
    border-radius: 3px;
    font-size: 15px;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    > div {
      display: inline-block;
      margin-inline-start: var(--s-3);
    }
  }

  &[open] summary {
    margin-bottom: var(--s0);
  }

  > div {
    padding: 0 var(--s-1);
  }
}

.source-consulted-at {
  font-size: 14px;
  font-family: var(--ff-sans);
  opacity: 0.6;
}

.source-list-title {
  margin: 0;
  font-size: 15px;
}

.source-header {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: var(--s-2) var(--s-1) var(--s-1);
  background-color: var(--green-2);

  > div:first-child {
    flex: 0 0;
  }

  > details {
    flex: 1 1;
    overflow: auto;
  }
}

.source-list-header {
  padding: var(--s-1);
  color: var(--color-text-medium);
}

.attestations-list {
  padding: 0 var(--s-1) var(--s3);
}

.delete-attestation-entity-link {
  cursor: pointer;
  border-bottom: 2px solid var(--input-color);

  &:hover {
    border-bottom: 2px solid var(--input-color-hover);
  }
}
