.periods-overview-container {
  --period-border-color: var(--color-bg-depth-2);
  --period-background-color: var(--color-bg-depth-4);

  padding: var(--s-3);
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    --period-border-color: var(--color-bg-depth-3);
    --period-background-color: var(--grey-5);

    background-color: var(--period-border-color);
  }

  @media only print {
    break-inside: avoid;
  }
}

.periods-overview {
  --period-border-color: var(--color-bg-depth-2);
  --period-background-color: var(--color-bg-depth-4);

  display: flex;
  align-items: flex-start;
  text-align: left;
}

.periods-overview-period {
  border-right: 1px solid var(--period-border-color);
  background-color: var(--period-background-color);

  &:first-child {
    margin-right: 1.47%;
  }

  .periods-overview-item,
  .periods-overview-sub-item {
    min-height: 7px;
    font-size: 10px;
    line-height: 1.2;
    overflow: hidden;

    [data-layout='expand'] & {
      padding: var(--s-3);
      min-height: 36px;
    }

    &[data-selected='true'] {
      background-color: var(--color-bg-depth-5);
      color: var(--color-text-inverse);
    }
  }

  .periods-overview-sub {
    display: flex;
  }

  .periods-overview-sub-item {
    border-right: 1px solid var(--period-border-color);

    &:last-child {
      border-right: 0;
    }
  }
}

.periods-overview-modal {
  .modal-body {
    width: 96vw;
    max-width: 1400px;
  }

  .modal-description {
    overflow-x: scroll;
  }
}
