.link-popover {
  display: flex;
  max-width: 210px;
  align-items: flex-start;
  padding: 4px 10px;
  background-color: var(--color-bg-depth-1);
  border-radius: 3px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(calc(-100% + 28px));
  font-family: var(--ff-serif);
  font-size: 16px;
  cursor: pointer;

  > div span:first-child {
    font-size: 20px;
  }
}
