// *************************************
//
// Tooltip module
// Egypto
//
// *************************************

.tooltip-content {
  z-index: var(--z-index-tooltip);
  background-color: var(--color-bg-depth-6);
  color: var(--color-text-inverse);
  font-size: 12px;
  border-radius: 3px;
  padding: 5px 10px;
  max-width: 260px;
  opacity: 0;
  animation: fadeIn 0.15s ease-in forwards;

  &[data-theme='light'] {
    background-color: var(--color-bg-depth-1);
    color: var(--color-text);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  &[data-theme='blue'] {
    background-color: var(--blue-2);
    color: var(--color-text);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  &[dir='rtl'] {
    text-align: right;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
