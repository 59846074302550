.source-summary {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--s0);
  padding: var(--s-1);
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  & + &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: var(--s-1);
    left: var(--s-1);
    height: 1px;
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.source-summary-title {
  margin: 0;
  font-size: 17px;
  font-weight: 700;
  font-family: var(--ff-serif);
}

.source-summary-id,
.source-summary-periods {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
}

.source-summary-periods {
  font-family: var(--ff-serif);
  font-weight: 400;
}

.source-summary-attestations-count {
  opacity: 0.7;
  font-size: 13px;
}

.source-summary-arrow {
  .thesaurus[dir='rtl'] & {
    transform: rotate(180deg);
  }
}
