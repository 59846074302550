.search-filter-position-menu {
  position: relative;
  z-index: 200;
}

.search-filter-position-button {
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  padding: 5px 6px;
  border: 1px solid var(--color-bg-depth-6);
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: var(--color-bg-depth-6);
  color: var(--color-text-inverse);
  font-family: var(--ff-sans);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  .arrow {
    fill: currentcolor;
  }
}

.search-filter-position-menu {
  .menu {
    top: 35px;
    right: 0px;
  }

  .selected-position {
    flex: 0 0 auto;
    width: 12px;
  }
}
