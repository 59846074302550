.menu-item {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 6px;
  padding: 7px 10px;
  margin: 0;
  border: 0;
  border-radius: var(--s-3);
  background-color: transparent;
  font-size: 14px;
  white-space: nowrap;
  color: var(--color-text);
  cursor: pointer;
  pointer-events: all;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .menu[data-theme='dark'] & {
    color: var(--color-text-inverse);

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  &[data-active='true'] {
    background-color: var(--color-bg-depth-5);
    color: var(--color-text-inverse);
  }

  &[disabled] {
    opacity: 15%;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }

  svg {
    fill: currentcolor;
  }
}

.menu-item-check {
  flex: 0 0 auto;
  width: 12px;
  fill: currentcolor;
}
