// *************************************
//
// App header
//
// *************************************

.app-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  background-color: var(--color-bg-depth-5);
  padding: 0 10px;

  @media (min-width: 520px) {
    height: 80px;
  }
}

// -------------------------------------
// Submodules
// -------------------------------------

.app-header-logo {
  order: 1;
  flex: 0 0 auto;

  a {
    display: flex;
    align-items: center;
    min-height: 100%;
  }
}

.app-header-logo-image {
  width: 70px;

  @media (min-width: 520px) {
    width: 100px;
    margin: 0 10px;
  }
}

.app-header-logo-baseline {
  display: none;
  max-width: 120px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--color-text-medium);

  @media (min-width: 936px) {
    display: block;
  }
}

.app-header-middle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  order: 3;
  flex: 0 1 auto;

  @media (min-width: 520px) {
    order: 2;
    flex: 1 1 auto;
    justify-content: flex-start;
    min-width: 220px;
    max-width: 540px;
  }
}

.app-header-create-entry-button {
  display: none;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  order: 2;
  margin-inline-start: 0.5em;
  border: 0;
  border-radius: 6px;
  background-color: var(--blue-8);
  color: var(--color-text-inverse);

  @media (min-width: 700px) {
    display: flex;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    background-color: var(--blue-7);
  }

  &:disabled {
    opacity: 0.5;
  }

  svg {
    width: 12px;
    height: 12px;
    fill: currentcolor;
  }
}

.app-header-right {
  order: 2;
  flex: 1 1 auto;
  margin-inline-end: 0.5em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;

  @media (min-width: 520px) {
    order: 3;
    flex: 0 1 auto;
    flex-direction: row;
    justify-content: flex-end;
    margin-inline-end: 0;
    margin-inline-start: 0.5em;
  }
}

.app-header-separator {
  display: none;
  height: 50px;
  width: 1px;
  background-color: var(--border-color);

  @media (min-width: 700px) {
    display: block;
  }
}

.user-menu {
  display: none;
  order: 3;
  margin: 0 15px;
  white-space: nowrap;

  @media (min-width: 700px) {
    display: block;
  }

  a {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
