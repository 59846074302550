.tag {
  display: flex;
  align-items: center;
  gap: var(--s-3);
  font-size: 15px;
  font-family: var(--ff-sans);
  background-color: var(--color-bg-depth-3);
  color: var(--color-text-subtle);
  border-radius: 20px;
  padding: 1px 12px;
  cursor: inherit;

  &[data-deleteable='false'] {
    font-size: 12px;
    padding: 1px 8px;
  }

  &[data-deleteable='true'] {
    padding-right: 5px;
  }

  &[data-theme='green'] {
    background-color: var(--green-3);
    color: var(--color-bg-depth-2);
  }

  button {
    border: 0;
    padding: 4px;
    background-color: transparent;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    cursor: pointer;
    color: inherit;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  svg {
    display: block;
    fill: currentcolor;
  }
}
