.editor-popover-overlay {
  position: absolute;
  z-index: calc(var(--z-index-tooltip) + 1);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}

.editor-popover {
  position: absolute;
  z-index: calc(var(--z-index-tooltip) + 2);
  transform: translateY(-36px);
  pointer-events: none;

  > * {
    pointer-events: all;
  }
}
