.thesaurus-search-field-container {
  position: relative;
  padding: var(--s-2) var(--s-1);
  background-color: var(--green-2);
}

.thesaurus-search-field {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 0;
  padding: var(--s-2) 0;
  padding-inline-start: var(--s-1);
  padding-inline-end: 32px;
  color: var(--color-text-inverse);

  &:focus {
    outline: 0;
  }

  &:focus-visible {
    outline: 2px solid rgba(255, 255, 255, 0.25);
  }

  &::placeholder {
    opacity: 0.25;
  }
}

.thesaurus-search-field-icon {
  position: absolute;
  top: 12px;
  width: 22px;
  height: 22px;
  stroke: var(--color-text-inverse);

  .thesaurus[dir='ltr'] & {
    right: 16px;
  }

  .thesaurus[dir='rtl'] & {
    left: 16px;
  }
}
