.graphy-attestation-title-holders {
  background-color: var(--color-bg-depth-3);
  padding: var(--s0);

  .checkbox-container {
    margin-top: var(--s-1);
    justify-content: flex-end;
  }
}

.graphy-attestation-title-holders-title {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.04em;
  color: var(--color-text-subtle);
  opacity: 0.5;
  text-transform: uppercase;
  margin-bottom: var(--s-3);
  white-space: nowrap;
}
