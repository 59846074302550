.individual-relative {
  padding: var(--s-2) var(--s-1);
  color: var(--color-text);
  cursor: pointer;

  &:hover {
    background-color: var(--color-bg-depth-3);
  }

  & + & {
    border-top: 1px solid var(--input-color);
  }

  .entry-nuance-container {
    padding: 0;
  }

  .entry-nuance {
    font-size: 16px;
  }
}

.individual-relative-transliteration {
  font-size: 21px;
  color: var(--color-text-subtle);
  margin-bottom: 0;
}

.individual-relative-transliteration-unpublished-label {
  opacity: 0.6;
  font-family: var(--ff-serif);
  font-size: 17px;
}

.individual-relative--edition {
  position: relative;
  background-color: var(--color-bg-depth-3);
  padding: var(--s-1);
}
