.editor-context-menu {
  display: flex;
  gap: var(--s-3);
  padding: var(--s-4);
  background-color: var(--color-bg-depth-1);
  border-radius: 6px;
  box-shadow: 0 2px 8px var(--menu-shadow-color);

  button {
    justify-content: center;
    width: 32px;
    height: 32px;

    &[data-selected='true'] {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.editor-context-menu-transliteration-button,
.editor-context-menu-entry-link-button {
  padding: 7px;
}
