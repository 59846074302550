// *************************************
//
// Button
//
// *************************************

.button {
  -moz-user-select: none;
  user-select: none;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--s-1);
  font-family: var(--ff-sans);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-text-subtle);
  min-height: 22px;
  min-width: 22px;
  padding: 9px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background-color: var(--color-bg-depth-2);

  &:hover {
    background-color: var(--color-bg-depth-3);
  }

  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }

  &[data-theme='white'] {
    color: var(--white);
    background-color: rgba(255, 255, 255, 0.2);

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  &[data-theme='green'] {
    color: var(--white);
    background-color: var(--green-2);

    &:hover {
      background-color: var(--green-3);
    }
  }

  &[data-theme='transparent'] {
    color: var(--color-text-subtle);
    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &[data-theme='red'] {
    color: var(--white);
    background-color: var(--red-1);

    &:hover {
      background-color: var(--red-2);
    }
  }

  &[data-size='small'] {
    gap: var(--s-2);
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    padding: 4px 6px 3px;
  }

  &[data-size='large'] {
    gap: var(--s-1);
    border-radius: 7px;
    font-size: 15px;
    font-weight: 700;
    padding: 9px 16px;
    text-transform: capitalize;
  }

  &[data-size='full'] {
    display: flex;
    width: 100%;
    gap: var(--s-1);
    border-radius: 0;
    font-size: 15px;
    font-weight: 400;
    padding: 16px 24px;
    text-transform: unset;
  }

  svg {
    width: 12px;
    height: 12px;
    fill: currentcolor;
  }
}

// -------------------------------------
// States
// -------------------------------------

.button {
  [disabled='disabled'] &,
  &[disabled='disabled'] {
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
  }
}
