.dropdown-menu {
  position: relative;
  color: var(--color-text-inactive);

  &:hover,
  &[data-open='true'] {
    color: var(--color-text-subtle);
  }

  .menu {
    top: calc(100% + 4px);
  }

  &[data-position='right'] .menu {
    right: 0;

    [dir='rtl'] & {
      right: auto;
      left: 0;
    }
  }

  [role='menuitem'] {
    position: relative;
  }

  [role='menuitem'][data-separator='true'] {
    padding-top: 7px;

    &::before {
      content: '';
      position: absolute;
      margin-top: 3px;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: var(--input-color);
    }
  }

  .menu[data-theme='dark'] [role='menuitem'][data-separator='true']::before {
    background-color: var(--border-color);
  }
}

.dropdown-button {
  display: flex;
  align-items: center;
  gap: var(--s-2);
  padding: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;

  .arrow {
    fill: var(--color-text-subtle);
  }
}
