.counter-flag {
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  color: var(--color-bg-depth-2);
  background: url('../../../public/bg-counter-flag.svg') top left no-repeat;
  background-size: 100%;
}
