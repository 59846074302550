.entry-dictionary-group {
  & + & {
    margin-top: var(--s1);
    border-top: 1px solid var(--input-color);
  }
}

.entry-dictionary-group-title {
  font-family: var(--ff-sans);
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 var(--s-1);
  color: var(--color-text-inactive);

  .entry-dictionary-group + .entry-dictionary-group & {
    padding-top: var(--s-2);
  }
}

.entry-dictionary {
  margin: var(--s-3) 0 0;
}

.entry-dictionary-label {
  color: var(--color-text-inactive);
  font-family: var(--ff-sans);
  font-size: 15px;
  font-style: italic;

  span {
    cursor: default;
  }
}

.entry-dictionary-value {
  font-family: var(--ff-serif);
  color: var(--color-text);

  .dictionaries-layout[dir='rtl'] & {
    text-align: right;
  }

  .public-DraftEditor-content[contenteditable] {
    border: 0;
  }
}
