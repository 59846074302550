.graphy-attestation {
  position: relative;
  cursor: pointer;

  [dir='rtl'] & {
    text-align: right;
  }

  > div:first-child {
    position: relative;
    padding: var(--s-1) 0;
    z-index: 1;

    @media only screen {
      padding: var(--s-1) var(--s0);
      background-color: var(--green-1);

      &:hover {
        background-color: #b3c1b3;
      }
    }
  }

  [data-publishable='true'] > & > div:first-child {
    background-color: var(--blue-2);
  }
}

.graphy-attestation-actions {
  position: absolute;
  z-index: 10;
  display: none;
  top: 6px;
  right: 6px;

  .graphy-attestation:hover & {
    display: block;
  }
}

.graphy-attestation-no-source {
  padding: var(--s-1) var(--s0);
  background-color: var(--orange-2);
  color: var(--white);
  font-size: 13px;

  p {
    margin: var(--s-2) 0;
    opacity: 0.75;
  }
}

.graphy-attestation-source-title {
  font-family: var(--ff-serif);
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.graphy-attestation-source-id {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
}

.graphy-attestation-reference {
  font-size: 15px;
  margin-bottom: 0;

  .editor {
    width: auto;
    min-height: auto;
  }
}

.graphy-attestation-source-periods {
  font-family: var(--ff-serif);
  font-size: 16px;
}

.graphy-attestation-transliteration,
.graphy-attestation-traduction {
  font-family: var(--ff-serif);
}

.graphy-attestation-nuance {
  padding: var(--s-3) var(--s0) var(--s-1);
  background-color: var(--color-bg-depth-2);

  [dir='rtl'] & {
    text-align: right;
  }

  [contenteditable] {
    font-family: var(--ff-serif);
    font-weight: 700;
    color: var(--color-text);
  }

  label {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: var(--color-text-medium);
    margin: 0;
  }
}

.nuance-autocomplete-container {
  z-index: 1000;
  width: 100%;
  background-color: var(--color-bg-depth-1);
  overflow: auto;
  border-radius: 0 0 6px 6px;
  box-shadow: 0px 2px 8px var(--menu-shadow-color);

  &[data-placement='top'] {
    border-radius: 6px 6px 0 0;
  }
}

.nuance-autocomplete-container [role='option'] {
  justify-content: flex-start;
  text-align: left;

  .editor {
    min-height: auto;
  }

  [contenteditable] {
    font-size: 14px;
    font-weight: 400;
  }
}
