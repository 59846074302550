.nuance-container {
  position: relative;

  @media only screen {
    & + & {
      border-top: 1px solid var(--input-color);
    }
  }

  summary {
    &::before {
      top: 26px;
    }

    [contenteditable] {
      font-size: 17px;
      font-family: var(--ff-serif);
    }
  }

  details {
    &[open] {
      @media only screen {
        background-color: var(--color-bg-depth-3);
      }
    }

    @media only screen {
      > div {
        padding: 0 var(--s-1) var(--s0);
      }
    }
  }

  .graphy {
    @media only screen {
      padding: 0 var(--s-1);
      background-color: var(--green-0);
    }
  }
}

.nuance-summary {
  .periods-overview {
    padding-bottom: var(--s-1);
  }
}

.nuance-summary-content {
  display: flex;
}

.nuance-summary-description {
  flex: 1 1 auto;
  padding: var(--s-1) 0;

  @media only screen {
    padding-inline-end: var(--s-1);
  }

  [dir='rtl'] & {
    text-align: right;
  }
}

.nuance-summary-flags {
  pointer-events: all;
  padding-bottom: var(--s-2);
  flex: 0 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:empty {
    display: none;
  }

  > div:not(.counter-flag):first-child:last-child {
    margin-top: auto;
  }

  @media only print {
    display: none;
  }
}
