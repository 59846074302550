// *************************************
//
// List item
//
// *************************************

// -------------------------------------
// Modifiers
// -------------------------------------

.entry-summary {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-top: 1px solid var(--input-color);
  padding: var(--s0) var(--s1) var(--s-1) var(--s0);
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &.is-shown > div {
    opacity: 50%;
  }

  &.is-unpublished {
    background-color: rgb(23, 155, 255, 0.1);

    &:hover {
      background-color: rgb(23, 155, 255, 0.2);
    }
  }

  &.is-highlighted {
    color: var(--color-text-inactive);

    .highlighted {
      color: var(--color-text);
    }
  }
}

// -------------------------------------
// Submodules
// -------------------------------------
.entry-summary-left {
  order: 1;
  flex: 0 0 50%;
}

.entry-summary-transliteration {
  font-family: var(--ff-serif);
  font-size: 26px;
  font-style: italic;
  white-space: normal;
  margin-bottom: 6px;
  vertical-align: top;
}

.entry-summary-nuance {
  font-family: var(--ff-serif);
  font-size: 17px;

  p {
    margin: 0;
  }
}

.entry-summary-graphy {
  order: 2;
  height: 50px;
  flex: 0 0 50%;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  opacity: 80%;
}

.entry-summary {
  &:hover .entry-info,
  &:hover .entry-edition-status {
    visibility: visible;
  }

  .entry-info {
    order: 1;
    flex: 1 1 auto;
    color: var(--color-text-medium);
  }

  .entry-edition-status {
    width: 35px;
    height: 12px;
    order: 2;
  }

  .public-status {
    order: 3;
  }

  .entry-info,
  .entry-edition-status {
    visibility: hidden;
  }
}

.entry-summary-v4-tag-container {
  position: absolute;
  right: 12px;
  bottom: 32px;
}
