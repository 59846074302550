// *************************************************
//
// Entry tabs
//
// *************************************************

.entry-tabs-selectors {
  display: flex;
  gap: var(--s-2);
  margin: 0;
  padding: 0 var(--s-2);
  list-style-type: none;
}

.entry-tabs-selector {
  padding: 0 var(--s-2);
  cursor: pointer;
  color: var(--color-text-inactive);
  border-bottom: 4px solid transparent;

  &:focus {
    outline: none;
  }

  &:hover {
    color: var(--color-text-subtle);
  }

  &.is-selected {
    cursor: default;
    color: var(--color-text);
    border-bottom-color: var(--color-text);
  }

  &.is-disabled {
    cursor: default;
    color: var(--color-text-inactive);
  }

  &.is-disabled.is-selected {
    border-bottom-color: transparent;
  }

  svg {
    fill: currentcolor;
    width: 22px;
    height: 22px;
  }
}

.entry-tabs-selector-icon {
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
}
