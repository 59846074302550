// *************************************
//
// Utilities
// Global utilities
//
// *************************************

// ----- Clearfix ----- //

.clearfix:before,
.clearfix:after {
  display: table;
  content: ' ';
}

.clearfix:after {
  clear: both;
}

// ----- Clearfix ----- //

.clear {
  clear: both;
}

// ----- Hidden ----- //

.hidden {
  visibility: hidden;
  display: none;
}

// ----- Ellipsis ----- //

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// -------------------------------------
// Display
// -------------------------------------

.d-b {
  display: block;
}

.d-i {
  display: inline;
}

.d-ib {
  display: inline-block;
}

.d-n {
  display: none;
}

.d-t {
  display: table;
}

// -------------------------------------
// Margins
// -------------------------------------

// ----- All ----- //

.l-ws {
  margin: 30px;
}

// ----- Top ----- //

.l-ws-top {
  margin-top: 30px;
}

// ----- Bottom ----- //

.l-ws-bottom {
  margin-bottom: 30px;
}

// ----- Vertical ----- //

.l-ws-vertical {
  margin-top: 30px;
  margin-bottom: 30px;
}

// ----- Center ----- //

.l-center {
  margin-left: auto;
  margin-right: auto;
}

// -------------------------------------
// Padding
// -------------------------------------

// -------------------------------------
// Positioning
// -------------------------------------

.l-fullscreen {
  width: 100%;
  max-width: 100%;
}

.l-left {
  float: left;
}

.l-right {
  float: right;
}

.p-abs {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.p-rel {
  position: relative;
}

.p-static {
  position: static;
}

// -------------------------------------
// Text
// -------------------------------------

// ----- Alignment ----- //

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.t-left {
  text-align: left;
}

// ----- Family ----- //

.t-serif {
  font-family: var(--ff-serif) !important;
}

.t-sans {
  font-family: var(--ff-sans) !important;
}

// ----- Size ----- //
.t-big {
  font-size: 20px;
}

.t-normal {
  font-size: 16px;
}

.t-medium {
  font-size: 14px;
}

.t-small {
  font-size: 12px;
}

.t-xsmall {
  font-size: 11px;
}

// ----- Style ----- //

.t-italic {
  font-style: italic;
}

.t-underline {
  text-decoration: underline;
}

// ----- Transform ----- //

.t-upper {
  text-transform: uppercase;
}

// ----- Weight ----- //

.t-regular {
  font-weight: normal;
}

.t-bold {
  font-weight: bold;
}

// -------------------------------------
// States
// -------------------------------------

// ----- Pointable ----- //

.is-pointable {
  cursor: pointer;
}

.is-removing {
  pointer-events: none;
}

.respect-linebreaks {
  white-space: pre-wrap;
}

.has-help {
  cursor: help;
}

// -------------------------------------
// Shadows
// -------------------------------------

.shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.blur {
  -webkit-filter: opacity(20%);
  -moz-filter: opacity(20%);
  -o-filter: opacity(20%);
  -ms-filter: opacity(20%);
  filter: opacity(20%);
}

// -------------------------------------
// Menus
// -------------------------------------

%menuButton {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  border: none;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
}

.menu-arrow-button {
  @extend %menuButton;
  fill: var(--color-text-subtle);
  width: 8px;
  height: 5px;
  background-color: transparent;
  padding: 5px 6px 10px 6px;

  .arrow {
    width: 8px;
    height: 5px;
  }

  .menu-opened & {
    padding: 4px 6px 11px 6px;
  }
}

// -------------------------------------
// Animations
// -------------------------------------

.pulseOpacity {
  animation: pulseOpacityFrames 0.6s infinite;
}

@keyframes pulseOpacityFrames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rotateWaiting {
  margin: 40px;
  width: 24px;
  height: 24px;
  border: 5px solid var(--color-edition);
  border-radius: 12px;
  border-right: 5px solid transparent;
  animation: spinWaiting 1s infinite ease-in-out;
}

@keyframes spinWaiting {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotateLoading {
  margin-top: 150px;
  width: 34px;
  height: 34px;
  border: 5px solid rgba(23, 155, 255, 0.1);
  border-radius: 17px;
  border-right: 5px solid var(--color-edition);
  animation: spinWaiting 0.6s infinite ease-in-out;
}

@keyframes spinLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
