// *************************************
//
// Entry previewed
//
// *************************************

.entry.entry-preview {
  box-shadow: 0px 2px 8px rgba(66, 66, 63, 0.6);
  margin-inline-start: 6px;

  .entry-header {
    justify-content: space-between;
  }
}

// -------------------------------------
// TOOLBAR
// -------------------------------------

.entry-header-left {
  display: flex;
  align-items: center;
  gap: 6px;

  .icon-eye-open-svg {
    fill: var(--color-text-inactive);
  }

  .entry-preview-title {
    font-size: 12px;
    font-weight: 700;
    color: var(--color-text-inactive);
    text-transform: uppercase;
  }
}

.entry.entry-preview > .arrow {
  position: absolute;
  top: 5px;
  right: calc(100% - 1px);
  pointer-events: none;
  fill: var(--color-bg-depth-2);

  .entries[dir='rtl'] & {
    right: auto;
    left: calc(100% - 1px);
    transform-origin: center center;
    transform: rotate(180deg);
  }
}
