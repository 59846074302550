.editor {
  width: 100%;
  min-width: 100px;
  min-height: 28px;
  font-family: inherit;
  color: inherit;

  @media only print {
    max-width: 80ch;
  }
}

.editor,
.DraftEditor-root {
  height: 100%;

  @media only print {
    height: auto;
  }
}

/* Draftjs specific styles */
.DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  height: inherit;
}

.editor .public-DraftEditor-content {
  &[contenteditable='false'] {
    -webkit-user-select: none !important;
    user-select: none !important;
  }

  &[contenteditable='true'] {
    cursor: text;
    -webkit-user-modify: read-write-plaintext-only;
    padding: 0 0 0.4em;
    border-bottom: 1px solid var(--input-color);

    &:hover {
      border-bottom-color: var(--input-color-hover);
    }

    &:focus-within {
      border-bottom-color: var(--input-color-focus);
    }

    .input-container[data-layout='horizontal'] & {
      border-bottom: 0;
    }
  }

  span[data-text='true'] {
    -webkit-user-select: text !important;
    user-select: text !important;
  }

  [data-block='true'] + [data-block='true'] {
    margin-top: var(--s-2);
  }
}

.DraftEditor-root {
  position: relative;
}

.DraftEditor-editorContainer {
  position: relative;
  z-index: 1;
  /* stylelint-disable-next-line thomaspaillot/enforce-variable */
  background-color: rgba(255, 255, 255, 0);
}

.public-DraftEditor-block {
  position: relative;
}

.public-DraftEditorPlaceholder-root {
  position: absolute;
  z-index: 1;
  width: 100%;
  opacity: 0.25;
  font-family: inherit;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}

.public-DraftStyleDefault-block {
  position: relative;
}
