.entry-tab-content-layout {
  margin-top: var(--s-1);

  @media only print {
    break-inside: avoid;
  }
}

.entry-tab-content-layout-title {
  display: flex;
  justify-content: space-between;
  color: var(--color-text);
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.04em;
  font-family: var(--ff-sans);
  text-transform: uppercase;
  padding-bottom: var(--s-3);
  padding-inline-start: var(--s-1);
  padding-inline-end: var(--s-1);
  border-bottom: 1px solid var(--input-color);

  @media only print {
    font-size: 18px;
    padding: var(--s-1) 0;
    border-top: 2px solid var(--input-color);
    border-bottom: 0;
    margin: var(--s-1) 0;
    break-after: avoid;
  }

  &.no-border {
    border-bottom: 0;

    @media only print {
      border-top: 0;
    }
  }

  .entry-read &,
  .entry-preview &,
  .entry-print & {
    padding-inline-start: 0;
  }

  a {
    font-weight: 400;
    text-transform: none;
    text-decoration: none;
    color: var(--color-text-inactive);

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      color: var(--color-text-inactive);
    }

    &:focus-visible {
      outline: 1px solid var(--border-color);
    }

    @media only print {
      display: none;
    }
  }
}

.entry-tab-content-layout-body {
  &:not(.no-top-space):not(.no-space) {
    padding-top: var(--s-1);
  }

  .entry-edition &:not(.no-space) {
    padding: var(--s-1);

    &.no-top-space {
      padding-top: 0;
    }
  }
}
