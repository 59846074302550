.entry-info-popup {
  display: flex;
  position: relative;
  white-space: nowrap;
  color: var(--color-text-inactive);

  .menu {
    display: none;
    top: 100%;
  }

  &:hover {
    color: var(--color-text-subtle);

    .menu {
      display: block;
    }
  }
}

.entry-id {
  font-size: 11px;
  text-transform: uppercase;
  color: var(--color-text-medium);
}

.entry-save-message {
  font-size: 13px;
}

.entry-info-popup-content {
  padding: 8px 12px 4px;

  .entry-id,
  .entry-save-message {
    color: var(--color-text);
  }
}
