.individual-sequence--edition {
  position: relative;
  padding: var(--s-1) var(--s0) var(--s-1) 0;

  & + & {
    border-top: 1px solid var(--input-color);
  }

  &[data-publishable='true'] {
    background-color: var(--blue-1);
  }

  textarea {
    resize: none;
  }

  .main-nuances-editor {
    font-size: 16px;
  }

  details[open] {
    > div {
      padding: var(--s-1) 0 0 var(--s1);
    }
  }

  details:not([open]) {
    .input-container {
      pointer-events: none;
      border: 0;

      textarea {
        resize: none;
      }
    }
  }
}

.individual-sequence--creation {
  padding: var(--s-1) var(--s0);
}

.individual-sequence {
  .entry-nuance-container {
    padding: 0;
  }

  .entry-nuance {
    font-size: 16px;
  }

  @media only screen {
    & + & {
      border-top: 1px solid var(--input-color);
    }
  }

  summary {
    padding-top: var(--s-1);
    padding-bottom: var(--s-1);
  }

  details[open] {
    background-color: var(--color-bg-depth-3);

    > div {
      padding: 0 var(--s0) var(--s0);
    }
  }
}

.individual-sequence-transliteration {
  font-size: 18px;
}

.individual-sequence-counter {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: all;

  &:empty {
    display: none;
  }

  @media only print {
    display: none;
  }
}

.individual-sequence-actions {
  display: none;
  position: absolute;
  top: 0;
  right: 0;

  .individual-sequence--edition:hover & {
    display: block;
  }
}
