.attestation-summary {
  position: relative;
  padding: var(--s-1);
  background-color: var(--green-1);
  color: var(--color-text);
  cursor: pointer;

  & + &,
  .attestation-form + & {
    margin-top: var(--s-2);
  }

  .thesaurus[dir='rtl'] & {
    text-align: right;
  }
}

.attestation-summary-actions {
  position: absolute;
  z-index: 10;
  top: 6px;
  right: 6px;
  display: none;

  .attestation-summary:hover & {
    display: block;
  }
}

.attestation-summary-reference {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
}

.attestation-summary-transliteration,
.attestation-summary-traduction {
  color: inherit;
  font-family: var(--ff-serif);
  margin: 0;
  font-size: 17px;
}
