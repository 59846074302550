.individual-relatives-relative {
  background-color: var(--color-bg-depth-2);
  padding: var(--s-2) var(--s-1);

  & + & {
    margin-top: var(--s-3);
  }
}

.individual-relatives-section-label {
  color: var(--color-text-inactive);
  text-transform: uppercase;
  font-family: var(--ff-sans);
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: var(--s-3) 0 0;

  .entry-read &,
  .entry-preview & {
    border-top: 2px solid var(--input-color);
  }
}

.individual-relatives-read-relative {
  padding: var(--s-2) 0;

  & + & {
    border-top: 1px solid var(--input-color);
  }

  .entry-nuance-container {
    padding: var(--s-3) 0;
  }

  .entry-nuance {
    font-size: 16px;
  }
}
