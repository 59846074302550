.icon-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;

  &[data-interactive='true'] {
    color: var(--color-text-inactive);
  }

  &[data-interactive='true']:hover {
    color: var(--color-text-subtle);
  }

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }

  &[data-filled='true'] svg {
    fill: currentcolor;
  }

  &[data-filled='false'] svg {
    fill: none;
    stroke: currentcolor;
  }
}
