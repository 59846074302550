.menu {
  position: absolute;
  z-index: var(--z-index-dropdown);
  padding: var(--s-4);
  border-radius: 6px;
  background-color: var(--color-bg-depth-4);
  max-height: 280px;
  overflow-y: auto;
  box-shadow: 2px 2px 0px var(--menu-shadow-color);

  [dir='rtl'] & {
    box-shadow: -2px 2px 0px var(--menu-shadow-color);
  }

  &[data-theme='dark'] {
    background-color: var(--color-bg-depth-6);
    box-shadow: 1px 2px 5px var(--menu-shadow-color);

    [dir='rtl'] & {
      box-shadow: -1px 2px 5px var(--menu-shadow-color);
    }
  }

  &[data-theme='light'] {
    background-color: var(--color-bg-depth-1);
    box-shadow: 0px 2px 8px var(--menu-shadow-color);
  }
}
