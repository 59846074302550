.flag-icon {
  position: relative;
  display: block;
  flex: 0 0 auto;

  svg {
    position: relative;
    z-index: 1;
    display: block;
  }
}

.flag-icon--small {
  width: 12px;
  height: 8px;
}

.flag-icon--medium {
  width: 16px;
  height: 12px;
}

.flag-icon-tint-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  mix-blend-mode: screen;
}
