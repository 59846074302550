// *************************************
//
// Input module
// VÉgA
//
// *************************************

.transliteration-keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 var(--s-3) var(--s-1);
  align-self: center;

  @media (min-width: 520px) {
    margin: 0 var(--s-1) var(--s1);
  }
}

.transliteration {
  font-style: italic;
  font-family: var(--ff-serif);
}

// -------------------------------------
// Modifiers
// -------------------------------------

.transliteration--tall {
  font-size: 36px;

  .transliteration-input {
    font-size: 36px;
    line-height: 1.4;
    height: 62px;
  }
}

.attestation-transliteration {
  color: var(--color-text);
}

// -------------------------------------
// Submodules
// -------------------------------------
.transliteration-keyboard-key {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--ff-serif);
  font-style: italic;
  font-weight: 200;
  text-transform: none;
  height: 30px;
  width: 26px;
  margin-bottom: 4px;
  margin-inline-end: 2px;
  padding: 1px 2px;
  font-size: 18px;
  color: var(--color-text);
  background-color: var(--color-bg-depth-2);
  border: 0;
  border-radius: 4px;
  fill: none;
  stroke: currentcolor;

  @media (min-width: 520px) {
    height: 30px;
    width: 30px;
    margin-inline-end: 4px;
    padding: 1px 4px;
  }

  &:hover {
    background-color: var(--color-bg-depth-4);
  }

  &[data-active='true'] {
    background-color: var(--color-text);
    stroke: var(--color-text-inverse);
  }
}

.transliteration-keyboard-spacer {
  display: inline-block;
  width: 18px;
  background-color: transparent;
}

.transliteration-input {
  font-style: italic;
  font-weight: 400;
  font-size: 19px;
  color: var(--color-text);

  &::placeholder {
    color: var(--color-text-medium);
  }
}

[dir='ltr'].transliteration-input {
  &::placeholder {
    padding-left: 4px;
  }
}

[dir='rtl'].transliteration-input {
  &::placeholder {
    padding-right: 4px;
  }
}
