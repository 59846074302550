.attestation-form {
  background-color: var(--white);
  color: var(--color-text);
  padding: var(--s-1);
  box-shadow: -3px 0 17px rgba(0, 0, 0, 0.1);

  .attestation-summary + & {
    margin-top: var(--s-2);
  }

  .editor,
  input {
    font-family: var(--ff-serif);
  }

  input[name='karnakId'] {
    font-family: var(--ff-sans);
  }
}

.attestation-form-reference[data-layout='horizontal'] {
  label {
    top: -1px;
  }

  .editor {
    font-family: var(--ff-sans);
    font-weight: 700;
  }
}
