.source-form {
  padding: var(--s-1);
  background-color: var(--white);
  color: var(--color-text);
}

.source-form-period-selector-label {
  font-size: 11px;
  font-weight: 700;
  margin-top: var(--s-1);
  text-transform: uppercase;
  color: var(--color-text-medium);
}

.source-form .dropdown-menu .button {
  svg {
    width: 18px;
    height: 18px;
  }
}
