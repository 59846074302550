.entry-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24px;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  color: var(--color-bg-depth-2);
  fill: var(--color-bg-depth-2);
  font-size: 12px;
  font-weight: 700;
  font-family: var(--ff-sans);
  text-transform: uppercase;
  padding: 6px 5px 4px;
  margin: 0 2px;
  cursor: pointer;

  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }

  &:hover {
    background-color: var(--color-bg-depth-4);

    .entry.entry-edition &,
    .entry.entry-creation & {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &.is-square {
    padding: 5px;
    min-width: 24px;
  }

  &.is-active {
    background-color: var(--color-bg-depth-5);

    .entry.entry-edition & {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  > svg {
    width: 12px;
    height: 12px;
    fill: var(--color-text-subtle);

    .entry.entry-edition &,
    .entry.entry-creation & {
      fill: var(--color-bg-depth-2);
    }
  }
}
