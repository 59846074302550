// *************************************
//
// Icon button
//
// *************************************

.icon-button {
  -moz-user-select: none;
  user-select: none;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--ff-sans);
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-text-subtle);
  height: 22px;
  min-width: 22px;
  padding: 0;
  border-radius: 3px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background-color: var(--color-bg-depth-2);

  &:hover {
    background-color: var(--color-bg-depth-3);
  }

  &[data-theme='green'] {
    color: var(--white);
    background-color: var(--green-2);

    &:hover {
      background-color: var(--green-3);
    }
  }

  &[data-theme='transparent'] {
    color: var(--color-text-subtle);
    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
