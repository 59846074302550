.individual-title--edition {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--s-3);
  background-color: var(--color-bg-depth-2);
  padding: var(--s-2) var(--s-1) var(--s-2) var(--s-3);
  font-family: var(--ff-serif);
  font-style: italic;
  cursor: pointer;
  touch-action: none;

  &.is-dragging {
    opacity: 0.4;
  }

  &.is-drag-overlay {
    color: var(--color-text-subtle);
    background-color: var(--color-bg-depth-4);
  }

  & + & {
    margin-top: var(--s-3);
  }
}

.individual-title-handle {
  cursor: grab;
  width: 12px;
  height: 18px;
  padding: 2px;
  opacity: 0;

  .individual-title--edition:not(.is-dragging):hover & {
    opacity: 0.5;
  }

  &:hover {
    opacity: 1;
  }

  svg {
    display: block;
    fill: var(--color-text-subtle);
  }
}

.individual-title-unpublished-label {
  color: var(--grey-5);
  font-style: normal;
}

.individual-title-action {
  display: none;
  position: absolute;
  top: var(--s-2);
  right: var(--s-2);

  .individual-title--edition:hover & {
    display: block;
  }
}
