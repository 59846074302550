// *************************************
//
// Search bar
//
// *************************************
.search-bar-container {
  flex: 1 1 auto;

  @media (min-width: 520px) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
  }
}

.search-bar {
  display: none;
  flex: 1 1 auto;
  position: relative;

  .search-bar-container.is-popup-open & {
    display: block;
  }

  @media (min-width: 520px) {
    display: block;
  }
}

.search-bar-mobile-container {
  display: flex;
  gap: var(--s-1);
  margin: 0 var(--s-2) var(--s-1);

  @media (min-width: 520px) {
    display: none;
  }
}

.search-bar-mobile {
  position: relative;
  flex: 1 1 auto;

  .search-bar-field {
    background-color: var(--color-bg-depth-2);
  }
}

.search-bar-mobile-cancel-button {
  background-color: transparent;
  border: 0;
}

.search-bar-mobile-button {
  display: block;

  @media (min-width: 520px) {
    display: none;
  }

  button {
    height: 35px;
    border-radius: 7px;
    padding: 6px;
  }

  svg {
    stroke: currentcolor;
  }
}

.search-bar-field {
  -webkit-appearance: none;
  width: 100%;
  padding: 6px 0;
  padding-inline-start: 8px;
  padding-inline-end: 40px;
  font-family: var(--ff-serif);
  font-size: 19px;
  line-height: var(--base-line-height);
  font-style: italic;
  font-weight: 400;
  text-indent: 5px;
  color: var(--color-text);
  background-color: var(--color-bg-depth-1);
  border: 0;
  border-radius: 7px;

  &::placeholder {
    color: var(--color-text-medium);
  }

  .suspens & {
    background-color: var(--color-bg-depth-2);
  }
}

.search-bar-icon {
  position: absolute;
  top: 10px;
  width: 22px;
  height: 22px;
  stroke: var(--color-text-medium);

  .search-bar-container[dir='ltr'] & {
    right: 10px;
  }

  .search-bar-container[dir='rtl'] & {
    left: 10px;
  }
}
