.entry-graphies-container {
  margin-top: var(--s-1);
}

.entry-graphies {
  .entry-edition & {
    border-bottom: 1px solid var(--input-color);
  }
}

.graphy-container {
  @media only screen {
    & + & {
      border-top: 1px solid var(--input-color);
    }
  }

  .entry-edition & {
    padding: 0 0 10px;
  }

  &[data-publishable='true'] {
    background-color: var(--blue-1);
  }

  .entry.entry-edition & details[open] {
    > div {
      padding: 0 var(--s-1);
    }
  }

  .entry:not(.entry-edition) & {
    details[open] {
      background-color: var(--color-bg-depth-3);

      > div {
        padding: 0 var(--s0);
      }
    }
  }
}

.graphy-attestation-container {
  padding: var(--s-1) 0;

  @media only screen {
    padding: var(--s-1);
  }
}

.entry-graphy-attestation-edit-container {
  border: 1px solid var(--input-color);
  padding: var(--s-1);

  .source-summary {
    background-color: var(--green-2);
    color: var(--white);
  }

  .attestation-form {
    padding: 0;
    box-shadow: none;
  }
}

.entry-graphy-toolbar {
  padding: 4px var(--s-1);
}

.graphy-menu-button {
  display: block;
  padding: 2px;
  background-color: transparent;
  border: 0;
  color: var(--color-text-medium);
  cursor: pointer;

  &:hover {
    color: var(--color-text-subtle);
  }
}

.entry-graphy-star {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 18px;
  height: 18px;
  color: var(--color-text-inactive);
}

.graphy-dropzone {
  position: relative;
  color: var(--color-bg-depth-5);
  text-align: center;

  &.filled {
    position: inherit;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 8px 12px;
    margin: 0;
    background-color: var(--color-text-inverse);
    color: var(--color-text-inactive);
    cursor: pointer;
    font-size: 16px;

    &:hover {
      color: var(--color-text-subtle);
      background-color: var(--color-bg-depth-2);
    }

    input {
      display: none;
    }
  }
}

.graphy-menu-file-input {
  margin: 0;
  font-size: inherit;

  input {
    display: none;
  }
}
