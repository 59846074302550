.app-lang-menu {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 15px;
  font-size: 12px;
  position: relative;

  > .flag-icon {
    outline: 1px solid var(--color-text-medium);
    opacity: 80%;
  }

  &:hover > .flag-icon {
    opacity: 100%;
  }

  .menu {
    display: none;
    top: 70%;
  }

  &[dir='ltr'] .menu {
    left: -15px;
  }

  &[dir='rtl'] .menu {
    right: -15px;
  }

  &:hover .menu {
    display: block;
  }

  .locale-name {
    flex: 1 1 auto;
  }
}
