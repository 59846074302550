.thesaurus {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 25vw;
  min-width: 320px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-depth-2);
  box-shadow: 0 -3px 17px rgba(0, 0, 0, 0.2);
  color: var(--white);
  height: 100%;

  &[dir='rtl'] {
    right: auto;
    left: 0;
  }
}

.thesaurus-header {
  flex: 0 0 auto;
  max-height: 100%;
}

.thesaurus-title {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
}

.thesaurus-content {
  flex: 1 1 auto;
  overflow: auto;
}
