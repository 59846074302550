// *************************************
//
// Entry edited
//
// *************************************

.entry.entry-edition {
  background-color: var(--color-bg-depth-1);
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);

  &[data-deleting='true'],
  &[data-loading='true'] {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      z-index: 1000;
      top: 38px;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--color-bg-depth-1);
      opacity: 0.85;
    }
  }

  &[data-deleting='true']::after {
    top: 0;
    opacity: 0.6;
    animation: pulse 0.8s infinite alternate;
  }

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    100% {
      opacity: 0.8;
    }
  }
}

.entry-edition.is-in-selection-mode {
  z-index: calc(var(--z-index-entries) + 10);
}

.entry-content {
  flex: 1 1 auto;
  padding: 0 var(--s-1) var(--s3);
  overflow-y: auto;

  .entry-edition & {
    padding: 0 0 var(--s3);
  }
}
