// *************************************
//
// Entry creation
//
// *************************************

.entry.entry-creation {
  background-color: var(--color-bg-depth-1);
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
}

// -------------------------------------
// TOOLBAR
// -------------------------------------

.entry-creation .entry-header {
  position: relative;
  z-index: var(--z-index-entries);
  background-color: var(--color-edition);
}
