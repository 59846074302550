// *************************************
//
// Search popup
//
// *************************************

.search-bar-popup-container {
  position: absolute;
  z-index: var(--z-index-header);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--s-4);
  width: 100%;
  height: 100vh;
  padding: var(--s-1) 0 0;
  background-color: var(--color-bg-depth-1);

  @media (min-width: 520px) {
    top: calc(100% + var(--s-2));
    left: auto;
    height: calc(
      100vh - 68px - var(--s-2)
    ); // For full height scrollable list : Viewpoint height - position of popup from top - bottom margin
    border-radius: 6px;
    box-shadow: 0 2px 16px var(--menu-shadow-color);
  }
}

.search-popup {
  position: relative;
  display: flex;
  flex-direction: column;
}

// -------------------------------------
// Submodules
// -------------------------------------

.search-popup-entry-count {
  font-size: 15px;
  font-weight: bold;
  padding: var(--s-1);
  min-height: 16px;
  color: var(--color-text);
}

.search-popup-searching {
  text-transform: uppercase;
}

.search-popup-list {
  flex: 1 1 auto;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.search-popup-filters {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 var(--s-1);
}

.search-popup-empty-message {
  padding: var(--s-1);
}

.search-tabs {
  margin-bottom: var(--s1);
  padding: 0 var(--s-1);
}

.search-tab-title {
  color: var(--color-text-subtle);
  background-color: transparent;
  padding: var(--s-3) var(--s-2);
  border: 0;
  border-bottom: 3px solid transparent;
  cursor: pointer;

  &:hover {
    color: var(--color-text);
  }

  &.is-active {
    border-bottom-color: var(--color-text-subtle);
  }
}
