.select-container {
  z-index: var(--z-index-dropdown);
  width: 340px;
  padding: var(--s-4);
  border-radius: 6px;
  background-color: var(--color-bg-depth-1);
  max-height: 280px;
  overflow-y: auto;
  box-shadow: 0px 2px 8px var(--menu-shadow-color);
}

.select-input-container {
  position: relative;
}

.select-input {
  width: 100%;
  border: 0;
  padding: var(--s-2) 0;
  padding-inline-start: var(--s1);
  background-color: transparent;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-text-inactive);
    opacity: 1;
  }
}

.select-input-icon {
  position: absolute;
  top: 6px;
  width: 22px;
  height: 22px;
  stroke: var(--color-text-medium);

  .individual-titles-layout[dir='ltr'] & {
    left: 0;
  }

  .individual-titles-layout[dir='rtl'] & {
    right: 0;
  }
}
